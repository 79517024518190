import { addMessages, getLocaleFromNavigator, init } from 'svelte-i18n';
import en from './locales/en/common.json';
import fr from './locales/fr/common.json';
import pl from './locales/pl/common.json';
import ro from './locales/ro/common.json';

addMessages('en', en);
addMessages('fr', fr);
addMessages('ro', ro);
addMessages('pl', pl);

export const fallbackLocale = 'en';

init(
	// When in development, for my tests, I want to start with French
	{
		fallbackLocale,
		initialLocale: getLocaleFromNavigator()
	}
);
